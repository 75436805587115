import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

const initialState = { data: [], status: "idle", error: null };

export const fetchUser = createAsyncThunk("user/fecthUser", async () => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_ENDPOINT}/api/user/get/lastapply/by/email`,
    {
      params: {
        email: window.localStorage.getItem("email"),
      },
    }
  );
  return response.data;
});

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchUser.pending]: (state, action) => {
      state.status = "loading";
    },
    [fetchUser.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.data = action.payload;
    },
    [fetchUser.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.error.message;
    },
  },
});

export const getUserData = (state) => state.user.data;

export default userSlice.reducer;
