import React, { lazy, useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { themeChange } from "theme-change";
import checkAuth from "./app/auth";
import initializeApp from "./app/init";

// Importing pages
const Layout = lazy(() => import("./containers/Layout"));
const Login = lazy(() => import("./pages/Login"));
const ForgotPassword = lazy(() => import("./pages/ForgotPassword"));
const Register = lazy(() => import("./pages/Register"));
const Documentation = lazy(() => import("./pages/Documentation"));
const Home = lazy(() => import("./pages/Home"));
const About = lazy(() => import("./pages/AboutUs"));
const Loker = lazy(() => import("./pages/Loker"));
const Apply = lazy(() => import("./pages/Apply"));
const PsikotesType = lazy(() => import("./pages/PsikotesType"));
const PsikotesLanding = lazy(() => import("./pages/PsikotesLanding"));
const Disc = lazy(() => import("./pages/Disc"));
const Msdt = lazy(() => import("./pages/Msdt"));
const Istse = lazy(() => import("./pages/Istse"));
const Istwa = lazy(() => import("./pages/istwa"));
const Istan = lazy(() => import("./pages/Istan"));
const Istge = lazy(() => import("./pages/istge"));
const Istra = lazy(() => import("./pages/Istra"));
const Istzr = lazy(() => import("./pages/Istzr"));
const Istfa = lazy(() => import("./pages/Istfa"));
const Istwu = lazy(() => import("./pages/Istwu"));
const Istme = lazy(() => import("./pages/Istme"));
const End = lazy(() => import("./pages/PsikotesEnd"));

// Initializing different libraries
initializeApp();

// Check for login and initialize axios
const token = checkAuth();

function App() {
  useEffect(() => {
    // 👆 daisy UI themes initialization
    themeChange(false);
  }, []);

  return (
    <>
      <Router>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/register" element={<Register />} />
          {/* <Route path="/documentation" element={<Documentation />} /> */}
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/detail/:id" element={<Loker />} />
          <Route path="/apply/:id" element={<Apply />} />
          <Route path="/type" element={<PsikotesType />} />
          <Route path="/psikotes" element={<PsikotesLanding />} />
          <Route path="/disc" element={<Disc />} />
          <Route path="/msdt" element={<Msdt />} />
          <Route path="/istse" element={<Istse />} />
          <Route path="/istwa" element={<Istwa />} />
          <Route path="/istan" element={<Istan />} />
          <Route path="/istge" element={<Istge />} />
          <Route path="/istra" element={<Istra />} />
          <Route path="/istzr" element={<Istzr />} />
          <Route path="/istfa" element={<Istfa />} />
          <Route path="/istwu" element={<Istwu />} />
          <Route path="/istme" element={<Istme />} />
          <Route path="/end" element={<End />} />

          {/* Place new routes over this */}
          <Route path="/app/*" element={<Layout />} />
          <Route
            path="*"
            element={
              <Navigate to={token ? "/app/welcome" : "/login"} replace />
            }
          />
        </Routes>
      </Router>
    </>
  );
}

export default App;
